<template>
  <q-page />
</template>

<script>
import { account } from 'api/book'
import { Notify } from 'quasar'
import loading from 'utils/loading'
import store from 'store'
import i18n from 'i18n'

export default {
  props: ['token', 'requester', 'journey'],
  beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']
    const paymentParameters = store.getters['payment/params']
    loading.start({
      message: i18n.t('loading.completing_booking'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    const params = {
      payment_method: 'account'
    }

    const { journey, travelType, reasonFor, labels, acceptance } = paymentParameters

    if (journey.reference) params.journey_reference = journey.reference
    if (journey.name) params.journey_name = journey.name
    if (travelType) params.travel_type = travelType
    if (reasonFor) params.reason_for = reasonFor
    if (labels) params.labels = labels
    if (acceptance) params.acceptance = acceptance

    account(paymentParameters.token, params)
      .then(response => {
        next(vm => {
          vm.$router.push({
            name: 'dashboard',
            params: {
              booking_reference: response.data.reference,
              content_type: response.data.content_type
            }
          })
        })
      })
      .catch(response => {
        Notify.create({
          message: i18n.t('error.default') + '. ' + i18n.t('error.try_again'),
          icon: 'fas fa-times-circle',
          color: 'negative'
        })
        loading.stop()
        next(false)
      })
  }
}
</script>
