<template>
  <q-page class="row justify-center" padding>
    <div class="maxWidth">
      <q-card>
        <q-tabs v-model="tabs" no-caps inline-label align="justify" active-bg-color="primary" indicator-color="transparent" active-color="white" class="bg-white">
          <q-tab :label="$t('payment_card.saved')" name="saved" icon="mdi-credit-card-multiple" />
          <q-tab :label="$t('add_new_card')" name="new" icon="mdi-credit-card-plus" />
        </q-tabs>
        <q-separator />
        <q-tab-panels v-model="tabs" class="panels" animated>
          <q-tab-panel name="saved" class="q-pa-none">
            <q-scroll-area ref="scrollArea" class="panelScroll">
              <q-list v-if="cards !== null" separator>
                <card-item
                  v-for="(card, index) in cards"
                  :key="`card${index}`"
                  v-bind="{card}"
                  :selected="card.reference === selectedCard"
                  @select="selectedCard = card.reference"
                  @delete-card="deleteCard"
                />
              </q-list>
              <div v-if="cards !== null" class="text-center q-pa-md">
                <div class="text-caption text-faded">
                  {{ cards.length ? $t('use_different_card') : $t('no_saved_cards') }}
                </div>
                <div>
                  <a href="" @click.prevent="tabs = 'new'">{{ $t('add_new_card') }}</a>
                </div>
              </div>
            </q-scroll-area>
          </q-tab-panel>
          <q-tab-panel name="new" class="q-pa-none">
            <q-scroll-area ref="scrollArea" class="panelScroll q-pa-md">
              <form class="col-12 maxWidth">
                <div id="sp-container" />
              </form>
              <div class="col-12 row">
                <q-input
                  v-model="booking.card_name"
                  class="col-10"
                  :label="$t('payment_card.name')"
                  :disable="!booking.card_save"
                  :readonly="!booking.card_save"
                />
                <q-checkbox
                  v-model="booking.card_save"
                  class="col justify-start"
                  dense
                  :label="$t('payment_card.save')"
                />
              </div>
              <address-form v-model="booking" :errors="$v" />
            </q-scroll-area>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
      <div class="text-faded text-center row">
        <q-btn
          class="q-ma-md full-width"
          :label="$t('complete_booking')"
          :disable="!completeBookingBtnEnabled"
          color="primary"
          size="17px"
          @click.prevent="checkoutMethod"
        />
        <sub
          class="col-12 text-center"
          v-html="$t('mobilleo_privacy_and_terms')"
        />
      </div>
    </div>
    <q-dialog v-model="protectedDialog" :maximized="$q.screen.lt.md">
      <q-card class="dialog bg-transparent">
        <q-card-section class="header bg-primary">
          <q-item dense class="q-pa-none q-ma-none">
            <q-item-section v-if="$q.screen.lt.md" side>
              <q-btn flat round dense icon="arrow_back" color="white" @click="close3DSecureDialog" />
            </q-item-section>
            <q-item-section>
              <div><strong>{{ $t('bank_authorisation') }}</strong> - {{ $t('three_d_secure') }}</div>
            </q-item-section>
            <q-item-section v-if="$q.screen.gt.md" side>
              <q-btn flat :label="$t('cancel_payment')" size="12px" color="primary" @click="close3DSecureDialog" />
            </q-item-section>
          </q-item>
        </q-card-section>
        <q-card-section class="iframeSection bg-white">
          <iframe v-if="threeDSDialog" ref="threeDSIframe" :src="localIframeSrc" />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>
<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import loadScript from 'utils/loadScript'
import cardItem from './cardItem'
import addressForm from './addressForm'
import loading from 'utils/loading'
import { savedCards, deleteCard } from 'api/user'
import { sessionKey } from 'api/sagepay'
import { Notify } from 'quasar'
import { creditcard as payByCard, preauthed as threeDSAuthed } from 'api/book'
import { debounce, pickBy } from 'lodash'
import { mapGetters } from 'vuex'
import store from 'store'

export default {
  name: 'PaymentCard',
  components: {
    cardItem,
    addressForm
  },
  data () {
    return {
      selectedMethod: 'saved',
      cards: null,
      sessionKey: null,
      checkoutReference: null,
      selectedCard: null,
      loading: false,
      threeDSDialog: false,
      threeDSPopup: null,
      booking: {
        first_name: null,
        last_name: null,
        address_1: null,
        address_2: null,
        city: null,
        post_code: null,
        country: null,
        state: null,
        card_save: true,
        card_name: null,
        merchant_session_key: null
      },
      listenForCompleteTransactionFunction: null
    }
  },
  computed: {
    ...mapGetters({
      sagepayThreeDSFields: 'sagepay/formFields',
      partner: 'partner',
      token: 'payment/token',
      requester: 'payment/requester',
      journey: 'payment/journey',
      reasonFor: 'payment/reasonFor',
      labels: 'payment/labels',
      travelType: 'payment/travelType',
      acceptance: 'payment/acceptance'
    }),
    localIframeSrc () {
      return window.origin + '/threeDSAuthentication'
    },
    redirectPath () {
      var path = this.localIframeSrc + '?complete=1'
      return path
    },
    protectedDialog: {
      get () {
        return this.threeDSDialog
      },
      set (open) {
        if (this.threeDSDialog && !open) {
          this.close3DSecureDialog(false)
          this.$q.dialog({
            title: this.$t('cancel_payment'),
            message: this.$t('confirm.cancel_payment'),
            cancel: true,
            persistent: true
          }).onCancel(() => {
            this.threeDSDialog = true
          })
        }
      }
    },
    tabs: {
      get () {
        return this.selectedMethod
      },
      async set (val) {
        let old = this.selectedMethod

        if (val === 'new') {
          loading.start({
            message: this.$t('loading.preparing.card_forms'),
            partnerSlug: this.partner.slug,
            spinnerColor: this.partner.hex
          })

          // If session key failed on mount, try again now
          if (!this.sessionKey) {
            try {
              const { data } = await sessionKey()
              this.sessionKey = data.merchant_session_key
            } catch (err) {
              Notify.create({
                message: this.$t('error.try_later'),
                color: 'negative',
                icon: 'clear'
              })
              this.selectedMethod = 'saved'
              loading.stop()
              return
            }
          }

          this.selectedMethod = val

          if (window.sagepayCheckout) {
            this.$nextTick(() => {
              this.checkoutReference = window.sagepayCheckout({
                merchantSessionKey: this.sessionKey,
                onTokenise: response => {
                  if (response.success) this.submit(response.cardIdentifier)
                }
              })
              this.checkoutReference.form()
              this.$nextTick(() => {
                setTimeout(() => {
                  loading.stop()
                }, 1000)
              })
            })
          } else {
            this.$q.dialog({
              title: this.$t('unable_add_new_card'),
              message: this.$t('javascript_required_new_card')
            })
            this.selectedMethod = 'saved'
            loading.stop()
          }
        } else if (old === 'new') {
          this.destroySagepayCheckout()
          this.selectedMethod = val
        } else {
          Notify.create({
            message: this.$t('error.try_later')
          })
        }
      }
    },
    completeBookingBtnEnabled () {
      return ((this.selectedMethod === 'new' && !this.$v.$invalid) || (this.selectedMethod === 'saved' && this.selectedCard)) && !this.loading
    }
  },
  validations () {
    if (this.selectedMethod === 'saved') {
      return {
        selectedCard: { required }
      }
    }

    return {
      booking: {
        first_name: { required },
        last_name: { required },
        address_1: { required },
        country: { required },
        city: { required },
        post_code: { required },
        state: {
          requiredIf: requiredIf(() => this.booking.country === 'US')
        },
        card_name: { requiredIf: requiredIf(() => this.booking.card_save) }
      }
    }
  },
  beforeDestroy () {
    this.destroySagepayCheckout()
  },
  async beforeMount () {
    const partner = store.getters.partner
    loading.start({
      message: this.$t('loading.validating_payment'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      loadScript(process.env.VUE_APP_SAGEPAY_URL)

      const { data } = await savedCards({ username: this.requester.value })
      this.cards = data
    } catch (err) {
      this.cards = []
      this.$q.notify({
        message: this.$t('error.retrieving_cards'),
        color: 'negative',
        icon: 'clear'
      })
    }

    try {
      const { data } = await sessionKey()
      this.sessionKey = data.merchant_session_key
      if (this.cards.length < 1) this.tabs = 'new'
    } catch (err) { }

    loading.stop()
  },
  methods: {
    close3DSecureDialog (useProtected = true) {
      if (useProtected) this.protectedDialog = false
      else this.threeDSDialog = false
      if (this.listenForCompleteTransactionFunction) window.removeEventListener('message', this.listenForCompleteTransactionFunction, false)
    },
    destroySagepayCheckout () {
      if (this.checkoutReference !== null) {
        this.checkoutReference.destroy()
        this.checkoutReference = null
      }
    },
    checkoutMethod: debounce(function () {
      if (this.tabs === 'saved') {
        this.submit()
      } else if (this.tabs === 'new') {
        this.$v.$touch()
        if (!this.$v.$error) {
          sessionKey().then(response => {
            this.sessionKey = response.data.merchant_session_key
            this.checkoutReference.tokenise({ newMerchantSessionKey: this.sessionKey })
          })
        } else {
          this.$q.notify(this.$t('check_validation_messages'))
        }
      }
    }, 300),
    submit (cardIdentifier = null) {
      loading.start({
        message: this.$t('loading.completing_booking'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })
      if (this.selectedMethod === 'new') {
        this.placeBooking({
          ...this.booking,
          merchant_session_key: this.sessionKey,
          card_identifier: cardIdentifier
        })
      } else if (this.selectedMethod === 'saved' && this.selectedCard) {
        this.placeBooking({
          card: this.selectedCard
        })
      }
    },
    placeBooking (params) {
      const { journey, travelType, reasonFor, labels, acceptance } = this

      if (journey.reference) params.journey_reference = journey.reference
      if (journey.name) params.journey_name = journey.name
      if (travelType) params.travel_type = travelType
      if (reasonFor) params.reason_for = reasonFor
      if (labels) params.labels = labels
      if (acceptance) params.acceptance = acceptance

      payByCard(this.token, pickBy(params)).then(response => {
        if (response.data.redirectUrl) {
          loading.start({
            message: this.$t('bank_authenticate_popup_text'),
            partnerSlug: this.partner.slug,
            spinnerColor: this.partner.hex
          })
          let url = response.data.redirectUrl
          let pareq = response.data.pareq
          let transactionId = response.data.transactionId

          this.$store.dispatch('sagepay/setFormFields', {
            formUrl: url,
            paReq: pareq,
            termUrl: `${process.env.VUE_APP_API_URL + '/three-ds-authentication/' + transactionId}?redirecturl=${this.redirectPath}`,
            merchantData: this.token
          })

          if (this.listenForCompleteTransactionFunction) window.removeEventListener('message', this.listenForCompleteTransactionFunction, false)

          this.listenForCompleteTransactionFunction = (event) => {
            if (event.data === 'transactionComplete') {
              window.removeEventListener('message', this.listenForCompleteTransactionFunction)
              loading.start({
                message: this.$t('loading.confirming.payment'),
                partnerSlug: this.partner.slug,
                spinnerColor: this.partner.hex
              })
              this.threeDSDialog = false
              threeDSAuthed(this.token, pickBy({
                ...params,
                'transaction_id': transactionId
              })).then(response => {
                loading.stop()
                this.$router.push({
                  name: 'dashboard',
                  params: {
                    booking_reference: response.data.reference,
                    content_type: response.data.content_type
                  }
                })
              }).catch(response => {
                loading.stop()
                this.$q.notify({
                  message: this.$t('error.try_later'),
                  color: 'negative',
                  icon: 'clear'
                })
              })
            }
          }

          window.addEventListener('message', this.listenForCompleteTransactionFunction, false)

          loading.stop()

          this.threeDSDialog = true
        } else {
          this.$router.push({
            name: 'dashboard',
            params: {
              booking_reference: response.data.reference,
              content_type: response.data.content_type
            }
          })
        }
      }).catch(response => {
        loading.stop()
        this.$q.notify({
          message: this.$t('error.try_later'),
          color: 'negative',
          icon: 'clear'
        })
      })
    },
    async deleteCard (reference) {
      try {
        await deleteCard({ username: this.requester.value }, reference)

        // Adjust display to match user expectation
        this.cards = this.cards.filter(card => card.reference !== reference)

        this.$q.notify({
          message: this.$t('payment_card.removed'),
          color: 'positive',
          icon: 'far fa-check-double'
        })
      } catch (err) {
        this.$q.notify({
          message: this.$t('error.removing_card'),
          color: 'negative',
          icon: 'clear'
        })
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  .panels
    height 480px
    max-height 60vh
  .panelScroll
    height 100%
    width 100%
  .maxWidth
    max-width 768px

  .dialog
    text-shadow none
    width 500px
    min-height 750px
    overflow hidden
    @media (max-width: 768px)
      margin 0
      max-width 100vw
      min-height 100vh

  .iframeSection
    display flex
    padding 0
    height 750px
    @media (max-width: 768px)
      min-height 100vh
    iframe
      border 0
      width 100%

</style>
