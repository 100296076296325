import http from './http'

export function account (token, params) {
  return http.post(`/book/${token}`, { payment_method: 'account', ...params })
}

export function paypal (token, params) {
  return http.post(`book/${token}`, params)
}

export function creditcard (token, params) {
  return http.post(`book/${token}`, params)
}

export function preauthed (token, params) {
  return http.post(`book/${token}`, params)
}

export function supplier_handled (token, params) {
  return http.post(`book/${token}`, { payment_method: 'supplier_handled', ...params })
}
