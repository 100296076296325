<template>
  <q-page />
</template>

<script>
import { Notify } from 'quasar'
import loading from 'utils/loading'
import { paypal } from 'api/book'
import store from 'store'
import i18n from 'i18n'

export default {
  props: ['token', 'requester', 'journey', 'labels'],
  beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']
    loading.start({
      message: i18n.t('loading.completing_booking'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    let params = {
      payer_id: to.params.data.payerID,
      payment_id: to.params.data.paymentID
    }

    if (to.params.journey.reference) {
      params.journey_reference = to.params.journey.reference
    } else {
      params.journey_name = to.params.journey.name
    }

    if (to.params.travelType) {
      params.travel_type = to.params.travelType
    }

    if (to.params.reasonFor) {
      params.reason_for = to.params.reasonFor
    }

    if (to.params.labels) {
      params.labels = to.params.labels
    }

    if (to.params.acceptance) {
      params.acceptance = to.params.acceptance
    }

    paypal(to.params.token, params)
      .then(response => {
        next(vm => {
          vm.$router.push({
            name: 'dashboard',
            params: {
              booking_reference: response.data.reference,
              content_type: response.data.content_type
            }
          })
        })
      })
      .catch(response => {
        next(from)
        loading.stop()
        Notify.create(i18n.t('error.default'))
      })
  }
}
</script>
